import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import Routes from '~/common/routes'
import { Form, Link, useLocation, useSubmit } from '@remix-run/react'
import { reset as resetAnalytics } from '~/lib/analytics/analytics'
import { Patient } from '~/domains/accounts'
import noUser from './assets/no-user.svg'
import { getAppGlobals } from '~/global-data'
import DeferredSkeleton from '../DeferredSkeleton'
import { TRACKED_FAQ_SECTION_KEY } from '~/common'
import useSentryFeedbackForm from '~/common/hooks/useSentryFeedbackForm'
import * as Sentry from '@sentry/remix'
import { GEOLOCATION_SESSION_KEY } from '~/lib/geolocation'

const menuItemClasses =
  'block py-2 body-sm text-neutral-60-primary-text hover:text-primary-30-default'

const UserMenu = ({
  patient,
  setShowLoginModal,
}: {
  patient?: Promise<Patient>
  setShowLoginModal: (show: boolean) => void
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const submit = useSubmit()
  const { setIsFeedbackWidgetVisible } = useSentryFeedbackForm()
  const location = useLocation()
  const isLoginRoute = location.pathname == Routes.LOGIN

  const { BOOKING_URL } = getAppGlobals()

  const commonSections: Array<React.ReactNode[]> = [
    [
      <button
        onClick={() => {
          setDropdownVisible(false)
          setIsFeedbackWidgetVisible(true)
        }}
        className={menuItemClasses}
        key="report-bug"
      >
        Report Bug
      </button>,
    ],
  ]

  const loggedInSections: Array<React.ReactNode[]> = [
    [
      <a
        href={BOOKING_URL}
        className={menuItemClasses}
        target="_blank"
        key="book-new-appointment"
      >
        Book New Appointment
      </a>,
      <Link
        to={Routes.APPOINTMENTS}
        className={menuItemClasses}
        onClick={() => setDropdownVisible(false)}
        key="my-appointments"
      >
        My Appointments
      </Link>,
      <Link
        to={Routes.PROFILE}
        className={menuItemClasses}
        onClick={() => setDropdownVisible(false)}
        key="my-account"
      >
        My Account
      </Link>,
    ],
    [
      <SignOutFormButton
        href={Routes.SIGN_OUT}
        label="Sign out"
        key="sign-out"
      />,
    ],
    ...commonSections,
  ]

  const loggedOutSections: Array<React.ReactNode[]> = [
    [
      <a
        href={BOOKING_URL}
        className={menuItemClasses}
        target="_blank"
        key="book-new-appointment"
      >
        Book New Appointment
      </a>,
    ],
    ...(!isLoginRoute
      ? [
          [
            <button
              className={menuItemClasses}
              onClick={() => {
                setShowLoginModal(true)
                setDropdownVisible(false)
              }}
              key="sign-in"
            >
              Sign In
            </button>,
          ],
        ]
      : []),
    ...commonSections,
  ]

  function signOut(event: React.FormEvent<HTMLFormElement>) {
    sessionStorage.removeItem(TRACKED_FAQ_SECTION_KEY)
    sessionStorage.removeItem(GEOLOCATION_SESSION_KEY)
    resetAnalytics()
    setDropdownVisible(false)
    Sentry.setUser(null)
    submit(event.currentTarget)

    // There is an edge case where the app crashes when logging out due to
    // some internal Remix issue so a hard refresh is necessary
    setTimeout(() => {
      window.location.href = Routes.LOGIN
    }, 100)
  }

  function SignOutFormButton({ href, label }: { href: string; label: string }) {
    return (
      <Form onSubmit={signOut} method="delete" action={href}>
        <button type="submit" className={menuItemClasses}>
          {label}
        </button>
      </Form>
    )
  }

  return (
    <>
      <div className="flex items-center relative lg:order-2">
        <button
          type="button"
          className="flex body-sm rounded-full md:mr-0"
          id="user-menu-button"
          aria-expanded={isDropdownVisible}
          onClick={() => setDropdownVisible(!isDropdownVisible)}
        >
          <span className="sr-only">Open user menu</span>
          {patient ? (
            <div className="w-7 h-7 rounded-full bg-success-30-primary flex items-center justify-center">
              <DeferredSkeleton promise={patient as Promise<Patient>}>
                {(patient) => (
                  <span className="text-white body-xs">
                    {patient?.firstName?.[0]}
                    {patient?.lastName?.[0]}
                  </span>
                )}
              </DeferredSkeleton>
            </div>
          ) : (
            <img src={noUser} className="w-7 h-7" alt="Open user menu" />
          )}
        </button>
      </div>

      {isDropdownVisible &&
        ReactDOM.createPortal(
          <>
            <div
              id="user-menu-dropdown"
              ref={dropdownRef}
              className={clsx(
                'absolute right-5 top-12 z-40 mt-1 w-56 body-lg list-none bg-white rounded-lg divide-y-1 divide-neutral-20-borders-dividers shadow-cancellation-reschedule-list px-3',
                { block: isDropdownVisible, hidden: !isDropdownVisible },
              )}
            >
              {(patient ? loggedInSections : loggedOutSections).map(
                (section, index) => (
                  <div key={index} className="py-2">
                    {section.map((item) => {
                      return item
                    })}
                  </div>
                ),
              )}
            </div>
            <div
              className="fixed inset-0 bg-black/30 z-20"
              onClick={() => setDropdownVisible(false)}
            />
          </>,
          document.getElementById('backdrop-root')!,
        )}
    </>
  )
}

export default UserMenu
